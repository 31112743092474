import React, { useContext, useEffect, lazy, Suspense } from 'react';
import '@trellixio/roaster-coffee/theme-coffee.css';
import 'assets/styles/main.css'; // not a CSS module to allow us to override Theme-coffee styles
import { Routes, Route, Navigate, useMatch } from 'react-router-dom';
import { useIsFetching } from '@tanstack/react-query';
import { Storage, resolveUrl, getAllURLsPattern, isURLWithParams, vendors } from 'helpers';
import { Urls, Params } from 'data';
import { CardContext, UserContext } from 'context';
import { ProtectedRoute, Sidebar, ProgressiveLoader } from 'components';

// components
const ErrorPage = lazy(() => import('components/ErrorPage'));
const SelectCard = lazy(() => import('components/SelectCard'));
// pages
const Login = lazy(() => import('pages/Login'));
const Logout = lazy(() => import('pages/Logout'));
const Home = lazy(() => import('pages/Home'));
const TrialOver = lazy(() => import('pages/TrialOver'));
const UpdatePayment = lazy(() => import('pages/UpdatePayment'));
const Health = lazy(() => import('pages/Health'));
const Analytics = lazy(() => import('pages/Analytics'));
const Transactions = lazy(() => import('pages/Transactions'));
const Referrals = lazy(() => import('pages/Referrals'));
// pages/Tiers
const ListTiers = lazy(() => import('pages/Tiers/ListTiers'));
const EditTier = lazy(() => import('pages/Tiers/EditTier'));
// pages/Rules
const ListRules = lazy(() => import('pages/Rules/ListRules'));
const AddRule = lazy(() => import('pages/Rules/AddRule'));
const EditRule = lazy(() => import('pages/Rules/EditRule'));
// pages/Onboarding
const BeansOnboarding = lazy(() => import('pages/Welcome'));
const BeansOnboardingStep = lazy(() => import('pages/Welcome/OnboardingStep'));
// pages/Settings
const SettingsLinks = lazy(() => import('pages/Settings/SettingsLinks'));
const SettingsAccount = lazy(() => import('pages/Settings/SettingsAccount'));
const SettingsGeneral = lazy(() => import('pages/Settings/SettingsGeneral'));
const SettingsTeam = lazy(() => import('pages/Settings/SettingsTeam'));
const SettingsPlan = lazy(() => import('pages/Settings/SettingsPlan'));
// pages/Integrations
const ListIntegrations = lazy(() => import('pages/Integrations/ListIntegrations'));
// pages/Members
const ListMembers = lazy(() => import('pages/Members/ListMembers'));
const EditMember = lazy(() => import('pages/Members/EditMember'));
const ListImports = lazy(() => import('pages/Members/Import/ListImports'));
const NewImport = lazy(() => import('pages/Members/Import/NewImport'));
// pages/Segments
const ListSegments = lazy(() => import('pages/Segments/ListSegments'));
const EditSegment = lazy(() => import('pages/Segments/EditSegment'));
const NewSegment = lazy(() => import('pages/Segments/NewSegment'));

const CONST_USER_TOKEN = 'user_token';
const CONST_SHOPKEY = 'shopKey';

const App = () => {
  const storage = new Storage(sessionStorage, 'boiler-');
  const token = storage.get(CONST_USER_TOKEN);
  const shopKey = storage.get(CONST_SHOPKEY);
  const { card } = useContext(CardContext);
  const { user } = useContext(UserContext);
  const isConnected = !!token;
  const isFetching = useIsFetching();
  // list of pages for which we hide the Sidebar
  // only pages where the user need to be authenticated
  const hideSidebarPages = [Urls.welcome.base, Urls.welcome.object, Urls.flag.trial_over, Urls.flag.delinquency];

  const userInputPath = () => {
    const link = new URL(window.location.href);
    return `/${shopKey}${link.pathname}${link.search}`;
  };

  const allURLs = getAllURLsPattern();

  const hideSidebar = hideSidebarPages.filter((URL) => useMatch(URL)).length > 0;

  useEffect(() => {
    if (isConnected && card && user && Params.IS_PROD_ENV) {
      // Only init vendors when all data are available
      vendors.init(card, user);
    }
  }, [isConnected, card, user]);

  return (
    <div id="boiler-main">
      {isFetching ? <ProgressiveLoader /> : null}

      {isConnected && !hideSidebar && <Sidebar />}

      <div id="page-detail" className="mainframe grid">
        <Suspense>
          <Routes>
            <Route
              index
              element={
                <ProtectedRoute isAllowed={isConnected}>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route path={Urls.front.login.base} element={<Login />} />
            <Route path={Urls.front.login.object} element={<Login />} />
            <Route path={Urls.front.logout} element={<Logout />} />
            <Route path={Urls.front.health} element={<Health />} />

            {/* Flag routes */}
            <Route path={Urls.flag.trial_over} element={<TrialOver />} />
            <Route path={Urls.flag.delinquency} element={<UpdatePayment />} />

            {/* Beans onboarding */}
            <Route path={Urls.welcome.base} element={<BeansOnboarding />} />
            <Route path={Urls.welcome.object} element={<BeansOnboardingStep />} />

            <Route element={<ProtectedRoute isAllowed={isConnected} />}>
              <Route path={Urls.home.base} element={<Home />} />

              {/* Rules pages */}
              <Route path={Urls.rules.base} element={<ListRules />} />
              <Route path={Urls.rules.add} element={<AddRule />} />
              <Route path={Urls.rules.object} element={<EditRule />} />

              <Route path={Urls.transactions.base} element={<Transactions />} />
              <Route path={Urls.referrals.base} element={<Referrals />} />

              {/* Tiers pages */}
              <Route path={Urls.tiers.base} element={<ListTiers />} />
              <Route path={Urls.tiers.object} element={<EditTier />} />

              {/* Settings pages */}
              <Route path={Urls.settings.base} element={<SettingsLinks />} />
              <Route path={Urls.settings.account} element={<SettingsAccount />} />
              <Route path={Urls.settings.general} element={<SettingsGeneral />} />
              <Route path={Urls.settings.team} element={<SettingsTeam />} />
              <Route path={Urls.settings.plan} element={<SettingsPlan />} />

              <Route path={Urls.integrations.base} element={<ListIntegrations />} />

              <Route path={Urls.members.base} element={<ListMembers />} />
              <Route path={Urls.members.import.base} element={<ListImports />} />
              <Route path={Urls.members.import.new} element={<NewImport />} />
              <Route path={Urls.members.object} element={<EditMember />} />

              <Route path={Urls.segments.base} element={<ListSegments />} />
              <Route path={Urls.segments.new} element={<NewSegment />} />
              <Route path={Urls.segments.object} element={<EditSegment />} />

              <Route path={Urls.select.card} element={<SelectCard />} />
              <Route path={Urls.analytics.base} element={<Analytics />} />

              {/* To ensure backward compatibility we redirect all old routes to the new ones */}
              {/* old route e.g. liana/rules */}
              {/* new route e.g. $shopKey/liana/rules */}
              {allURLs.map((url) => (
                <Route
                  exact
                  key={url}
                  path={url.replace('/:shopKey', '')}
                  element={
                    isURLWithParams(url) ? (
                      <Navigate to={userInputPath()} replace />
                    ) : (
                      <Navigate to={resolveUrl(url)} replace />
                    )
                  }
                />
              ))}
            </Route>

            {/* Catch-all for undefined routes */}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

export default App;
